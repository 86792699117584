//Import
import React from 'react'
import styled from 'styled-components'
import styles from './ModalCredito_styles'
import { Alert, Button, Table } from 'antd'
//Components
import ModalContainer from '../ModalContainer'
import ModalCentered from '../ModalCentered'
import ModalHeader from '../ModalHeader'
import ModalContent from '../ModalContent'
import ModalFooter from '../ModalFooter'
import FormInput from '../FormInput/FormInput'
import FormSelect from '../FormSelect/FormSelect'
import ModalBottom from '../ModalBottom'

// External components
import { Tabs } from 'antd'
import { formatNumber } from '../../helpers/formatNumber'
import ErrorInput from '../ErrorInput/ErrorInput'
import FormSelectCliente from '../FormSelectCliente/FormSelectCliente'
import FormInputInteres from '../FormInputInteres/FormInputInteres'
import FormTextArea from '../FormTextArea/FormTextArea'
const { TabPane } = Tabs

const callback = (key) => {}

/**
 *
 * Props:
 * type:String = Show modaltype 2 Options. "centered" "bottom"
 * show:Boolean = Modal Visibility
 * w:String = Modal width in css values
 * opacity:String / Number = Layout opacity values 0 to 1, 0.5 by default
 */

// Componente base
const ModalCredito_base = ({
  dataModal,
  dataModalDetalle,
  closeModal,
  closeModalDetalle,
  handleInputChange,
  handleInputBlur,
  errorsForm,
  clientes,
  productosExistentes,
  formasPagos,
  producto,
  creditosEnrutados,
  handleCrearSubmit,
  handleClienteNuevo,
  nombreClienteNuevo,
  mostrarNombreClienteNuevo,
  abrirModalCliente,
  interesRuta,
  handleCupoSelectCliente,
  show,
  type,
  w,
  opacity,
  ...props
}) => {
  //Modal type centered
  const modalCentered = () => {
    return (
      <ModalContainer>
        <ModalCentered w={w}>
          <form onSubmit={handleCrearSubmit}>
            <ModalHeader closeModal={closeModal} icon="">
              {' '}
              Crear Crédito{' '}
            </ModalHeader>
            <ModalContent>
              {mostrarNombreClienteNuevo && (
                <Alert
                  message={'Cliente: ' + nombreClienteNuevo}
                  type="info"
                  showIcon
                  action={
                    <Button onClick={abrirModalCliente} size="small">
                      Ver
                    </Button>
                  }
                />
              )}

              <FormSelectCliente
                name="clienteId"
                formlabel="Cliente"
                clientes={clientes}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                handleClienteNuevo={handleClienteNuevo}
                handleCupoSelectCliente={handleCupoSelectCliente}
              ></FormSelectCliente>
              {errorsForm.clienteId && (
                <ErrorInput textoError={errorsForm.clienteId} />
              )}
              <FormInput
                name="capital"
                formlabel="Capital"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="number"
              ></FormInput>
              {errorsForm.capital && (
                <ErrorInput textoError={errorsForm.capital} />
              )}
              <FormInput
                name="cuotas"
                formlabel="Cuotas"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="number"
              ></FormInput>
              {errorsForm.cuotas && (
                <ErrorInput textoError={errorsForm.cuotas} />
              )}
              <FormInputInteres
                name="interes"
                formlabel="Utilidad"
                //handleInputChange={handleInputChange}
                //handleInputBlur={handleInputBlur}
                type="number"
                interesRuta={interesRuta}
              ></FormInputInteres>
              {errorsForm.interes && (
                <ErrorInput textoError={errorsForm.interes} />
              )}
              <FormSelect
                name="formaPagoId"
                formlabel="Forma Pago"
                formasPagos={formasPagos}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
              ></FormSelect>
              {errorsForm.formaPagoId && (
                <ErrorInput textoError={errorsForm.formaPagoId} />
              )}
              <FormSelect
                name="enrutarCredito"
                formlabel="Enrutar después de"
                creditosEnrutados={creditosEnrutados}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
              ></FormSelect>
              {errorsForm.enrutarCredito && (
                <ErrorInput textoError={errorsForm.enrutarCredito} />
              )}
              <FormTextArea
                name="producto"
                formlabel="Producto"
                //productosExistentes={productosExistentes}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="text"
              ></FormTextArea>
              {errorsForm.productosExistentes && (
                <ErrorInput textoError={errorsForm.productosExistentes} />
              )}
              <FormTextArea
                name="descripcion"
                formlabel="Descripcion"
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                type="text"
              ></FormTextArea>
              {errorsForm.descripcion && (
                <ErrorInput textoError={errorsForm.descripcion} />
              )}
            </ModalContent>
            <ModalFooter closeModal={closeModal}></ModalFooter>
          </form>
        </ModalCentered>
      </ModalContainer>
    )
  }

  //Modal type bottom
  const modalBottom = () => {
    const colmuns = [
      {
        title: 'Valor',
        dataIndex: 'valor_cuota',
        render: (cuota) => formatNumber(cuota),
      },
      {
        title: 'Saldo',
        dataIndex: 'saldo_actual',
        render: (saldo) => formatNumber(saldo),
      },
      {
        title: 'Fecha',
        dataIndex: 'fecha',
      },
      {
        title: 'Hora',
        dataIndex: 'hora',
      },
    ]
    const data = []
    return (
      <ModalBottom>
        <ModalHeader icon="" noclose closeModalDetalle={closeModalDetalle}>
          {' '}
          {dataModalDetalle !== ''
            ? dataModalDetalle.cliente.nombre_completo
            : ''}{' '}
        </ModalHeader>
        <ModalContent>
          <Tabs defaultActiveKey="1" onChange={callback} className="ow-tabs">
            <TabPane tab="Pago" key="1">
              {dataModalDetalle !== '' ? (
                <>
                  <Table
                    columns={colmuns}
                    dataSource={data}
                    pagination={false}
                    rowKey="id"
                  >
                    {/* {dataModalDetalle.cuota.abonos.map((abono) => {
                      if (parseInt(abono.valor_abono) !== 0) {
                        data.push(abono)
                      }
                      return ''
                    })} */}
                  </Table>
                </>
              ) : (
                ''
              )}
            </TabPane>
            <TabPane tab="Info General" key="2">
              {dataModalDetalle !== '' ? (
                <>
                  <p>
                    <strong>Fecha Creación:</strong>{' '}
                    {dataModalDetalle.fecha_creacion}
                  </p>
                  <p>
                    <strong>Valor: </strong>{' '}
                    {formatNumber(dataModalDetalle.capital)}
                  </p>
                  <p>
                    <strong>Saldo: </strong>{' '}
                    {formatNumber(dataModalDetalle.cuota.saldo_actual_credito)}
                  </p>
                  <p>
                    <strong>Valor Cuota: </strong>{' '}
                    {formatNumber(dataModalDetalle.cuota.valor_cuota)}
                  </p>
                  <p>
                    <strong>Forma de Pago: </strong>{' '}
                    {dataModalDetalle.forma_pago.nombre}
                  </p>
                  <p>
                    <strong>Utilidad: </strong> {dataModalDetalle.interes} %
                  </p>
                  <p>
                    <strong>Calificación: </strong>{' '}
                    {dataModalDetalle.cliente.calificacion}
                  </p>
                  <p>
                    <strong>Dirección: </strong>{' '}
                    {dataModalDetalle.cliente.direccion}
                  </p>
                  <p>
                    <strong>Teléfono: </strong>{' '}
                    {dataModalDetalle.cliente.celular_1 ||
                      dataModalDetalle.cliente.celular_2}
                  </p>
                  <p>
                    <strong>Documento: </strong>{' '}
                    {dataModalDetalle.cliente.documento_identificacion}
                  </p>
                  <p>
                    <strong>Producto: </strong>
                    {dataModalDetalle.producto}
                  </p>
                  <p>
                    <strong>Descripción: </strong>{' '}
                    {dataModalDetalle.descripcion}
                  </p>
                </>
              ) : (
                ''
              )}
            </TabPane>
          </Tabs>
        </ModalContent>
      </ModalBottom>
    )
  }

  // Render
  return (
    <div {...props}>
      {type === 'centered'
        ? modalCentered()
        : type === 'bottom'
        ? modalBottom()
        : 'No Modal rendered'}
    </div>
  )
}
const ModalCredito = styled(ModalCredito_base)`
  ${styles}
`
export default ModalCredito
