import { types } from '../types/types'

export const abonosInitialState = {
  db: null,
  totalAbonos: 1,
  metaRecaudo: 1,
  copiaDd: null,
}

export const abonosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.abonos_todos: {
      return {
        ...state,
        db: action.payload.map((data) => data),
        copiaDb: action.payload.map((data) => data),
      }
    }

    case types.abonos_meta_recaudo_total_abonos: {
      return {
        ...state,
        totalAbonos: action.payload.totalAbonos,
        metaRecaudo: action.payload.metaRecaudo,
        // totalAbonos: "0",
        // metaRecaudo: "0",
      }
    }

    case types.abonos_buscar: {
      let busqueda = action.payload.toLocaleLowerCase()
      let resultado = state.copiaDb.filter((item) => {
        if (
          item.cliente.nombre_completo.toLocaleLowerCase().includes(busqueda)
        ) {
          return item
        }
      })
      return {
        ...state,
        db: resultado,
      }
    }

    case types.abonos_limpiar_filtro: {
      return {
        ...state,
        db: state.copiaDb,
      }
    }

    case types.abonos_registrar_cuota: {
      return {
        ...state,
        db: state.db.filter((item) => item.id !== action.payload.id),
        copiaDb: state.copiaDb.filter((item) => item.id !== action.payload.id),
        totalAbonos:
          parseInt(state.totalAbonos) + parseInt(action.payload.cuota),
      }
    }

    case types.abonos_actualizar_ubicacion: {
      let newData = state.db.map((el) =>
        el.id === action.payload.id ? action.payload : el
      )
      return {
        ...state,
        db: newData,
        copiaDb: newData,
      }
    }

    default:
      return state
  }
}
