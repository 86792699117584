//Import
import React from 'react'
import styled from 'styled-components'
import styles from './FormSelectCliente_styles'
import Select from 'react-select'
//import { Select } from "antd";

/**
 * Ant Design component props
 * https://ant.design/components/select/#Select-props
 *
 * Own Props
 * formLabel:String = shows label element if exist
 * */

// Componente base
const FormSelectCliente_base = ({
  clientes = null,
  handleInputBlur,
  handleInputChange,
  handleClienteNuevo = null,
  handleCupoSelectCliente = null,
  formlabel,
  name,
  ...props
}) => {
  const cupo = localStorage.getItem('cupo')
  const customStyles = {
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isDisabled ? 'red' : isSelected ? 'white' : undefined,
      }
    },
  }

  const f = {
    target: {
      name: 'clienteId',
    },
  }

  const options = [
    { value: '0', label: '***CREAR NUEVO CLIENTE***', data: cupo },
  ]
  if (clientes !== null) {
    clientes.map((el) => {
      if (el.estado === 0) {
        options.push({
          value: el.id,
          label: el.nombre_completo + ' **INACTIVO**',
          isDisabled: true,
        })
      } else {
        options.push({
          value: el.id,
          label: el.nombre_completo,
          data: el.cupo === '0' ? cupo : el.cupo,
          latitud: el.latitud === '0' ? '0' : el.latitud,
          longitud: el.longitud === '0' ? '0' : el.longitud,
        })
      }
    })
  }
  return (
    <div {...props}>
      <div className="ofc-select-label"> {formlabel} </div>
      <Select
        options={options}
        onBlur={handleInputBlur}
        onChange={(e) => {
          f.target.value = e.value.toString()
          handleClienteNuevo(f, e)
          handleInputChange(f)
          handleCupoSelectCliente(e)
        }}
        name={name}
        required
        placeholder="Seleccione un Cliente"
        styles={customStyles}
      />
    </div>
  )
}
const FormSelectCliente = styled(FormSelectCliente_base)`
  ${styles}
`
export default FormSelectCliente
