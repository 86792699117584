//Import
import React, { useEffect, useReducer, useState } from 'react'

//Layouts
import GroupHeader from '../../components/GroupHeader'
import GroupTableContainer from '../../components/GroupTableContainer'
import GroupItem from '../../components/GroupItem'
import GroupTableHeader from '../../components/GroupTableHeader'
import NavBar from '../../components/NavBar'

//vars
import ofc from '../../settings/variables'
import { abonosInitialState, abonosReducer } from '../../reducers/abonosReducer'
import Mensaje from '../../components/Mensaje/Mensaje'
import { URL_BASE_API, URL_BASE_API_V2 } from '../../settings/urlBaseApi'
import { helpHttp } from '../../helpers/helpHtpp'
import { types } from '../../types/types'
import Loader from '../../components/Loader/Loader'

import Modal from '../../components/Modal'
import { Modal as ModalAnt, message } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { formatNumber } from '../../helpers/formatNumber'

// Componente base

const AbonosView = ({ ...rest }) => {
  const [permisosConcedidos, setPermisosConcedidos] = useState(false)
  const [state, dispatch] = useReducer(abonosReducer, abonosInitialState)
  const { db, metaRecaudo, totalAbonos } = state
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [modalDetalleVisible, setModalDetalleVisible] = useState(false)
  const [dataModalDetalle, setDataModalDetalle] = useState('')
  const [dataModalDetalleAbonos, setDataModalDetalleAbonos] = useState('')

  const usarGpsAsesor = localStorage.getItem('usarGpsAsesor')
  // const codigoPais = localStorage.getItem('codigoPais')

  const handleClickRegistrarAbono = (e, el, cuota) => {
    e.preventDefault()
    let regexNumeroEntero = /^[0-9]\d*$/

    if (!regexNumeroEntero.test(cuota.trim())) {
      message.error({
        content: `El valor del abono debe ser entero sin decimales`,
      })
    } else if (cuota > parseInt(el.cuota.saldo_actual_credito)) {
      message.error({
        content: `El valor del abono supera el saldo del crédito`,
      })
    } else {
      ModalAnt.confirm({
        title: `${el.cliente.nombre_completo}`,
        content: (
          <div>
            <p>
              Abono {formatNumber(cuota)}
              <br />
              Saldo Restante{' '}
              {formatNumber(el.cuota.saldo_actual_credito - cuota)}
            </p>
          </div>
        ),
        cancelText: 'Cancelar',
        okText: 'Registrar',
        onOk: () => registrarAbono(cuota, el),
      })
    }
  }

  const registrarAbono = (cuota, el, abonos) => {
    // let url = URL_BASE_API + "/cuotas-creditos";
    let url = URL_BASE_API_V2 + '/abonos/crear-abono/' + el.cuota.id
    setLoading(true)
    helpHttp()
      .post(url, {
        body: {
          cuota_id: el.cuota.id,
          valor_abono: cuota,
        },
      })
      .then((res) => {
        if (!res.err) {
          if (res instanceof TypeError) {
            ModalAnt.error({
              title: 'Error',
              icon: <CloseCircleFilled style={{ color: 'red' }} />,
              content: (
                <div>
                  <p>
                    El abono al crédito del cliente $
                    {el.cliente.nombre_completo} por valor
                    {formatNumber(cuota)} no fue registrado, intentelo de nuevo!
                  </p>
                </div>
              ),
              okText: 'Cerrar',
            })
          } else {
            setError(null)
            dispatch({
              type: types.abonos_registrar_cuota,
              payload: {
                id: el.id,
                cuota: cuota,
              },
            })

            ModalAnt.confirm({
              title: `Abono Registrado`,
              icon: <CheckCircleFilled style={{ color: 'green' }} />,
              content: (
                <div>
                  <p>
                    El abono al crédito del cliente {el.cliente.nombre_completo}{' '}
                    por valor {formatNumber(cuota)} fue registrado con éxito!
                  </p>
                </div>
              ),
              cancelText: 'Aceptar',
              okText: 'Enviar a WhatsApp',
              onOk: () => {
                //const numeroTelefono = el.cliente.celular_1;
                let fecha = new Date(res.fecha)
                let fechaFormateada =
                  fecha.toLocaleDateString() + ' ' + fecha.toLocaleTimeString()
                let mensaje = `*Fecha*: ${fechaFormateada}\n`
                mensaje += `*Transacción*: ${res.codigo}\n`
                mensaje += `*Cliente*: ${el.cliente.nombre_completo}\n`
                mensaje += `*Valor Abonado*: ${formatNumber(res.valorAbono)}\n`
                mensaje += `*Saldo*:${formatNumber(res.saldoCredito)}`
                const whatsappUrlSinNumero = `https://wa.me/?text=${encodeURIComponent(
                  mensaje
                )}`
                // const whatsappUrl = `https://wa.me/${codigoPais}${numeroTelefono}?text=${encodeURIComponent(
                //   mensaje
                // )}`
                window.open(whatsappUrlSinNumero, '_blank')
              },
            })
          }
        } else {
          ModalAnt.error({
            title: 'Error',
            icon: <CloseCircleFilled style={{ color: 'red' }} />,
            content: (
              <div>
                <p>
                  El abono al crédito del cliente ${el.cliente.nombre_completo}{' '}
                  por valor {formatNumber(cuota)} no fue registrado, intentelo
                  de nuevo!
                </p>
              </div>
            ),
            okText: 'Cerrar',
          })
          setError(res)
        }
        setLoading(false)
      })
  }

  const closeModalDetalle = () => {
    setModalDetalleVisible(false)
  }

  const handleClickDetalle = (el) => {
    cargarAbonosCredito(el.cuota.credito_v2_id)
    setDataModalDetalle(el)
    setModalDetalleVisible(true)
  }

  const cargarAbonosCredito = (creditoId) => {
    let urlAbonosCreditos =
      //URL_BASE_API + "/cuotas-creditos/abonos/" + creditoId;
      URL_BASE_API_V2 + '/abonos/abonos-credito/' + creditoId
    setLoading(true)

    helpHttp()
      .get(urlAbonosCreditos)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null)
            setDataModalDetalleAbonos(res)
          } else {
            setError(res)
          }
        }
      })

    setLoading(false)
  }

  const filtroBusqueda = ({ target }) => {
    dispatch({
      type: types.abonos_buscar,
      payload: target.value,
    })
  }

  const limpiarFiltroBusqueda = () => {
    dispatch({
      type: types.abonos_limpiar_filtro,
    })
  }

  useEffect(() => {
    if (usarGpsAsesor === 'true') {
      const obtenerUbicacion = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log('Permiso de GPS concedido:', position)
            setPermisosConcedidos(true)
          })
        } else {
          console.error()
        }
      }
      obtenerUbicacion()
    }
  }, [usarGpsAsesor])

  const handleClickActualizarUbicacionCredito = (el) => {
    if (permisosConcedidos) {
      ModalAnt.confirm({
        title: `Actualizar Ubicación - ${el.cliente.nombre_completo}`,
        content: (
          <div>
            <p>
              ¿Está seguro de actualizar la ubicación del cliente{' '}
              {el.cliente.nombre_completo}?
            </p>
          </div>
        ),
        cancelText: 'Cancelar',
        okText: 'Actualizar',
        onOk: () => actualizarUbicacion(el),
      })
    } else {
      ModalAnt.warning({
        title: 'Sin acceso al GPS',
        content:
          'No se concedieron permisos de ubicación. Contacte al administrador.',
        okText: 'Aceptar',
      })
    }
  }

  const actualizarUbicacion = (el) => {
    setLoading(true)
    obtenerCoordenadas(el).then(() => {
      setLoading(false)
    })
  }

  const obtenerCoordenadas = (el) => {
    const promise = new Promise(function (resolve) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          if (
            position.coords.latitude !== 0 &&
            position.coords.longitude !== 0
          ) {
            actualizarUbicacionCredito(
              el,
              position.coords.latitude,
              position.coords.longitude
            )
          }
          resolve()
        },
        function (error) {},
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        }
      )
    })

    return promise
  }

  const handleClickIrUbicacion = (el) => {
    ModalAnt.confirm({
      title: `Ubicación - ${el.cliente.nombre_completo}`,
      content: (
        <div>
          <p>
            ¿Está seguro de ir a la ubicación del cliente{' '}
            {el.cliente.nombre_completo}?
          </p>
        </div>
      ),
      cancelText: 'Cancelar',
      okText: 'Ir Ubicación',
      onOk: () => {
        window.open(
          'https://maps.google.com/?q=' +
            el.credito.latitud +
            ',' +
            el.credito.longitud
        )
      },
    })
  }

  const actualizarUbicacionCredito = (el, latitud, longitud) => {
    const promise = new Promise(function (resolve) {
      let url = URL_BASE_API + '/actualizar-ubicacion-credito'
      helpHttp()
        .post(url, {
          body: {
            latitud: latitud,
            longitud: longitud,
            idCredito: el.credito.id,
            idCuotaCredito: el.id,
          },
        })
        .then((res) => {
          if (!res.err) {
            setError(null)
            dispatch({
              type: types.abonos_actualizar_ubicacion,
              payload: res,
            })
            message.success({
              content: `La ubicación del cliente ${el.cliente.nombre_completo} fue actualizada con éxito!`,
            })
          } else {
            setError(res)
          }
        })
      resolve()
    })
    return promise
  }

  // let url = URL_BASE_API + '/cuotas-creditos'
  // let urlMetaRecaudoTotalAbonos = URL_BASE_API + '/meta-recaudo-total-abonos'
  let url = URL_BASE_API_V2 + '/creditos/creditos-sin-abono-dia'
  let urlMetaRecaudoTotalAbonos =
    URL_BASE_API_V2 + '/abonos/meta-recaudo-total-abonos'
  useEffect(() => {
    setLoading(true)

    helpHttp()
      .get(urlMetaRecaudoTotalAbonos)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null)
            dispatch({
              type: types.abonos_meta_recaudo_total_abonos,
              payload: res,
            })
          } else {
            setError(res)
          }
        }
      })

    helpHttp()
      .get(url)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null)
            let creditos = Array.isArray(res.data)
              ? res.data
              : Object.values(res.data)
            dispatch({
              type: types.abonos_todos,
              payload: creditos.sort((a, b) => a.prioridad - b.prioridad),
            })
          } else {
            setError(res)
          }
        }
        setLoading(false)
      })
  }, [url, urlMetaRecaudoTotalAbonos])

  const fechaActual = new Date()
  const anio = fechaActual.getFullYear().toString()
  const mes = String(fechaActual.getMonth() + 1).padStart(2, '0')
  const dia = String(fechaActual.getDate()).padStart(2, '0')
  const fechaFormateada = `${anio}-${mes}-${dia}`
  return (
    <div {...rest}>
      <GroupHeader
        category="Abonos"
        filtroBusqueda={filtroBusqueda}
        limpiarFiltroBusqueda={limpiarFiltroBusqueda}
      />
      <Modal
        type="bottom"
        show={modalDetalleVisible}
        opacity="0.8"
        w={'15em'}
        closeModalDetalle={closeModalDetalle}
        dataModalDetalle={dataModalDetalle}
        dataModalDetalleAbonos={dataModalDetalleAbonos}
      ></Modal>
      <GroupTableContainer>
        <GroupTableHeader section="Abonos" />

        {db !== null ? (
          db.map((el) => {
            const fechaEstimadaPago = new Date(el.fecha_vencimiento)
            fechaEstimadaPago.setHours(23, 59, 59)

            const fechaCuota = new Date(el.cuota.fecha_cuota)
            fechaCuota.setHours(23, 59, 59)

            const diasDiferencia = Math.ceil(
              (fechaEstimadaPago - fechaActual) / (1000 * 60 * 60 * 24)
            )
            let estadoFecha
            if (diasDiferencia > 3) {
              estadoFecha = 'Activo'
            } else if (diasDiferencia > 0) {
              estadoFecha = 'Por vencer'
            } else {
              estadoFecha = 'Vencido'
            }

            const aldia =
              fechaCuota > fechaActual
                ? '$ 0'
                : el.cuota.valor_mora_credito < 0
                ? '$ 0 (Adelantado)'
                : formatNumber(el.cuota.valor_cuota)

            return (
              <GroupItem
                inputvalue={
                  el.cuota.valor_cuota - el.cuota.valor_mora_credito === 0
                    ? el.valor_cuota
                    : (
                        el.cuota.valor_cuota - el.cuota.valor_mora_credito
                      ).toString()
                }
                iconcolor={
                  el.cuota.valor_mora_credito === 0
                    ? ofc.primary
                    : el.cuota.valor_mora_credito > 0
                    ? ofc.yellow
                    : ofc.violet
                }
                key={el.id}
                username={el.cliente.nombre_completo + ' - ' + estadoFecha}
                fechaCreacionCredito={el.fecha_creacion}
                fechaCuotaCredito={
                  el.cuota.fecha_cuota + ' - ' + el.forma_pago.nombre
                }
                prestado={formatNumber(el.capital)}
                aldia={aldia}
                userdebt={formatNumber(el.cuota.saldo_actual_credito)}
                handleClickRegistrarAbono={(e, cuota) =>
                  handleClickRegistrarAbono(e, el, cuota)
                }
                textoAbono="Abono"
                handleClickDetalle={(e) => handleClickDetalle(el)}
                mostrarSaldo={true}
                usarGpsAsesor={usarGpsAsesor}
                latitud={el.latitud}
                longitud={el.longitud}
                handleClickActualizarUbicacionCredito={(e) =>
                  handleClickActualizarUbicacionCredito(el)
                }
                handleClickIrUbicacion={(e) => handleClickIrUbicacion(el)}
              />
            )
          })
        ) : (
          <Mensaje
            mensaje="No hay información para mostrar"
            type="info"
            title="Información"
          />
        )}
        {error && (
          <Mensaje
            mensaje={`Error ${error.status}: ${error.statusText}`}
            type="error"
            title="Error"
          />
        )}
      </GroupTableContainer>
      {loading && <Loader />}
      <NavBar
        view="abonos"
        texto1="Total Abonos"
        texto2="Meta de Recaudo"
        totalTexto2={formatNumber(metaRecaudo)}
        totalTexto1={formatNumber(totalAbonos)}
        progreso={parseInt((totalAbonos * 100) / metaRecaudo)}
      />
    </div>
  )
}

export default AbonosView
