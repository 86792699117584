//Import
import React from 'react'
import styled from 'styled-components'
import styles from './GroupItemVer_styles'

//Components
import { ICol, IRow } from '../Grid'
import ItemContainer from '../ItemContainer'
import FilledIcon from '../FilledIcon'
import RowText from '../RowText'
import ItemDelete from '../ItemDelete'
import ItemValue from '../ItemValue/ItemValue'
//vars

/**
 * DATA PROPS

 * username:String
 * userdebt:String
 * iconcolor:String from variables
 * uservalue:String Main user value
 * valuecolor: String -> Options red green blue  - default: green
 * 
 * 
 */

// Componente base
const GroupItemVer_base = ({
  username,
  userdebt,
  iconcolor,
  uservalue,
  valuecolor,
  texto,
  handleClickDetalle,
  handleClickEliminar,
  textoEliminar,
  mostrarSaldo,
  fechaCreacionCredito,
  fechaCuotaCredito,
  prestado,
  estadoAbono,
  aldia,
  ...props
}) => {
  return (
    <ItemContainer>
      <IRow valign="center" justify={'space-around'} py={1} px={1.2}>
        <ICol wf={2}>
          <FilledIcon icon="bag" color={iconcolor} />
        </ICol>

        <ICol w={45}>
          <RowText
            handleClickDetalle={handleClickDetalle}
            username={username}
            debt={userdebt}
            mostrarSaldo={mostrarSaldo}
            fechaCreacionCredito={fechaCreacionCredito}
            fechaCuotaCredito={fechaCuotaCredito}
            prestado={prestado}
            aldia={aldia}
          />
        </ICol>

        <ICol wf={6}>
          <ItemValue color={valuecolor}> {uservalue}</ItemValue>
        </ICol>
        <ICol wf={1} px={0.3}>
          {estadoAbono !== 30 ? (
            <ItemDelete
              handleClickEliminar={handleClickEliminar}
              textoEliminar={textoEliminar}
            />
          ) : (
            ''
          )}
        </ICol>
      </IRow>
    </ItemContainer>
  )
}
const GroupItemVer = styled(GroupItemVer_base)`
  ${styles}
`
export default GroupItemVer
