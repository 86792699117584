// Import
import React from 'react'
import styled from 'styled-components'
import styles from './RowText_styles'
import { formatNumber } from '../../helpers/formatNumber'

/***
 * PROPS
 * username:String = Set Username on item
 * debt:String Set debt on Saldo
 *
 */

// Componente base
const RowText_base = ({
  mostrarSaldo = false,
  handleClickDetalle,
  username,
  debt,
  fechaCreacionCredito,
  fechaCuotaCredito,
  prestado,
  aldia,
  enrutar = null,
  ...props
}) => {
  const moraLabel = aldia < 0 ? 'Adelantado' : 'Mora'
  const aldiaFormatted =
    aldia < 0 ? formatNumber(Math.abs(aldia)) : formatNumber(aldia)

  return (
    <div {...props} onClick={handleClickDetalle}>
      <div className="username">{username}</div>
      {debt ? (
        <>
          <div className="debt">
            {mostrarSaldo ? <b>Saldo</b> : ''} {debt}
          </div>
          {aldia !== 0 && aldia !== '$ 0' && (
            <div className="debt">
              <b>{moraLabel}</b> :{' '}
              {moraLabel === 'Mora' ? aldia : aldiaFormatted}
            </div>
          )}

          <div className="debt">
            <b>Prestado</b> : {prestado}
          </div>
          <div className="debt">
            <b>Fecha creación crédito</b> : {fechaCreacionCredito}
          </div>
          <div className="debt">
            <b>Fecha cuota</b> : {fechaCuotaCredito}
          </div>
        </>
      ) : (
        ''
      )}
      {enrutar ? <div style={{ fontSize: '1.2em' }}>{enrutar}</div> : ''}
    </div>
  )
}

const RowText = styled(RowText_base)`
  ${styles}
`

export default RowText
