export const types = {
  login: '[auth] login',
  logout: '[auht] logout',
  gastos_todos: '[gastos] ver todos',
  gastos_eliminar: '[gastos] eliminar',
  gastos_crear: '[gastos] crear',
  gastos_limpiar_filtro: '[gastos] limpiar filtro',
  gastos_buscar: '[gastos] buscar',
  abonos_todos: '[abonos] ver todos',
  abonos_actualizar_ubicacion: '[abonos] actualizar ubicacion',
  abonos_registrar_cuota: '[abonos] registrar cuota',
  abonos_meta_recaudo_total_abonos: '[abonos] meta recaudo total abonos',
  abonos_buscar: '[abonos] buscar',
  abonos_limpiar_filtro: '[abonos] limpiar filtro',
  creditos_todos: '[creditos] ver todos',
  creditos_limpiar_filtro: '[creditos] limpiar filtro',
  creditos_buscar: '[creditos] buscar',
  creditos_eliminar: '[creditos] eliminar',
  creditos_crear: '[creditos] crear',
  ver_abonos_todos: '[ver_abonos] ver todos',
  ver_abonos_buscar: '[ver_abonos] buscar',
  ver_abonos_limpiar_filtro: '[ver_abonos] limpiar filtro',
  ver_abonos_eliminar: '[ver_abonos] eliminar',
  cierres_rutas_todos: '[cierres_rutas] ver todos',
}
