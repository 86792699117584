import { types } from '../types/types'

export const verAbonosInitialState = {
  db: null,
  totalAbonos: 0,
  copiaDd: null,
}

export const verAbonosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ver_abonos_todos: {
      return {
        ...state,
        db: action.payload.map((data) => data),
        copiaDb: action.payload.map((data) => data),
        totalAbonos: action.payload.reduce(
          (prevValue, currentValue) =>
            prevValue + parseInt(currentValue.valor_abono),
          0
        ),
      }
    }

    case types.ver_abonos_eliminar: {
      return {
        ...state,
        db: state.db.filter((item) => item.id !== action.payload.id),
        totalAbonos: state.totalAbonos - action.payload.valor_abono,
        copiaDb: state.copiaDb.filter((item) => item.id !== action.payload.id),
      }
    }

    case types.ver_abonos_buscar: {
      let busqueda = action.payload.toLocaleLowerCase()
      let resultado = state.copiaDb.filter((item) => {
        if (
          item.cuota.credito.cliente.nombre_completo
            .toLocaleLowerCase()
            .includes(busqueda)
        ) {
          return item
        }
      })
      return {
        ...state,
        db: resultado,
        totalAbonos: resultado.reduce(
          (prevValue, currentValue) =>
            prevValue + parseInt(currentValue.valor_abono),
          0
        ),
      }
    }

    case types.ver_abonos_limpiar_filtro: {
      return {
        ...state,
        db: state.copiaDb,
        totalAbonos: state.copiaDb.reduce(
          (prevValue, currentValue) =>
            prevValue + parseInt(currentValue.valor_abono),
          0
        ),
      }
    }

    default:
      return state
  }
}
