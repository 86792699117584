//Import
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import styles from './FormSelect_styles'

/**
 * Ant Design component props
 * https://ant.design/components/select/#Select-props
 *
 * Own Props
 * formLabel:String = shows label element if exist
 * */

// Componente base
const FormSelect_base = ({
  gastosPorCategoria = null,
  formasPagos = null,
  creditosEnrutados = null,
  handleInputBlur,
  handleInputChange,
  formlabel,
  name,
  generarCuotasHoy = null,
  ...props
}) => {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    let isMounted = true // Variable de control para saber si el componente sigue montado

    if (!isInitialized && isMounted) {
      if (formasPagos?.length === 1) {
        handleInputChange({ target: { name, value: formasPagos[0].id } })
      }
      setIsInitialized(true)
    }

    return () => {
      isMounted = false // Actualiza la variable cuando el componente se desmonta
    }
  }, [formasPagos, handleInputChange, name, isInitialized])

  return (
    <div {...props}>
      <div className="ofc-select-label"> {formlabel} </div>

      {formasPagos?.length === 1 ? (
        // Mostrar la única forma de pago si solo hay una
        <div className="select-only-option">{formasPagos[0].nombre}</div>
      ) : (
        <select
          className="select"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          name={name}
          defaultValue={'DEFAULT'}
          required
        >
          <option disabled value="DEFAULT">
            Seleccione {formlabel}
          </option>
          {gastosPorCategoria !== null
            ? gastosPorCategoria.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.nombre}
                </option>
              ))
            : ''}
          {formasPagos !== null
            ? formasPagos.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.nombre}
                </option>
              ))
            : ''}
          {creditosEnrutados !== null ? (
            <option key={0} value={1}>
              Al inicio
            </option>
          ) : (
            ''
          )}
          {creditosEnrutados !== null
            ? creditosEnrutados.map((el) => (
                <option key={el.id} value={el.prioridad + 1}>
                  {el.prioridad} {el.nombre_completo}
                </option>
              ))
            : ''}
        </select>
      )}
    </div>
  )
}
const FormSelect = styled(FormSelect_base)`
  ${styles}
`
export default FormSelect
