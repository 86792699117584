//Import
import React from 'react'
import styled from 'styled-components'
import styles from './FormInputInteres_styles'

// Componente base
const FormInput_base = ({
  type = 'text',
  formlabel,
  //handleInputBlur,
  //handleInputChange,
  name,
  interesRuta,
  ...props
}) => {
  return (
    <div {...props}>
      <label htmlFor={formlabel}>{formlabel}</label>
      <input
        type={type}
        name={name}
        className="FormInput  transparent-input"
        //onBlur={handleInputBlur}
        //onChange={handleInputChange}
        required
        readOnly
        value={interesRuta ? interesRuta : ''}
      />
    </div>
  )
}
const FormInputInteres = styled(FormInput_base)`
  ${styles}
`
export default FormInputInteres
